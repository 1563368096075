<script>
import { resultsRoute } from '@src/router'
import { createTaskMutation } from '@src/mutations'
import CreateAnalysisStepper from '@comp/createAnalysis/CreateAnalysisStepper.vue'
import MapView from '@comp/createAnalysis/MapView.vue'
import dataSourceMap from '@src/utils/dataSources'
import indicatorMap from '@src/utils/indicators'
import {
  allDataSources,
  getLineStringFromGeojson,
  getMatchingDataSourcesForBbox,
  allIndicators,
} from '@src/queries'
import { useIndexStore } from '@src/store'
import { CREATE_ANALYSIS_STEPS } from '@src/constants'

export default {
  name: 'CreateAnalysis',
  components: {
    CreateAnalysisStepper,
    MapView,
  },
  data () {
    return {
      currentStep: CREATE_ANALYSIS_STEPS.ZONE,
      bbox: null,
      dataSources: [],
      lines: [],
      geojsonLines: {},
      matchingDataSources: [],
      sourcesListIsReady: true,
      selectedDataSource: null,
      possibleIndicators: [...indicatorMap.values()],
      selectedIndicators: [...indicatorMap.values()],
      outputFiles: {
        standaloneMaps: false,
        rawData: false,
      },
      runningGeojsonValidation: false,
      geojsonValidationError: false,
    }
  },
  watch: {
    async bbox () {
      if (this.bbox) {
        this.sourcesListIsReady = false
        await this.$graphqlQuery(
          getMatchingDataSourcesForBbox,
          {
            west: this.bbox[0],
            south: this.bbox[1],
            east: this.bbox[2],
            north: this.bbox[3],
          },
        ).then(response => {
          this.matchingDataSources = response.getMatchingDataSourcesForBbox
          this.sourcesListIsReady = true
        })
      }
    },
    async selectedDataSource () {
      if (this.selectedDataSource) {
        await this.$graphqlQuery(
          allIndicators,
          { dataSource: this.selectedDataSource.code },
        ).then(response => {
          this.possibleIndicators = response.allIndicators
          this.selectedIndicators = this.possibleIndicators.slice()
        })
      } else {
        this.possibleIndicators = []
        this.selectedIndicators = []
      }
    },
  },
  async mounted () {
    const store = useIndexStore()
    store.changeRoute(this.$options.name)
    await this.setDataSources()
  },
  methods: {
    async setDataSources () {
      await this.$graphqlQuery(allDataSources).then(response => {
        this.dataSources = response.allDataSources.map(ds => ({
          ...ds,
          color: dataSourceMap.get(ds.code).color,
        }))
      })
    },
    setBBox (bbox) {
      this.bbox = bbox // could be null
    },
    setLines (lines) {
      if (lines) {
        this.lines = lines.features // could be []
      }
    },
    setSelectedDataSource (dataSource) {
      this.selectedDataSource = dataSource
    },
    setSelectedIndicators (indicators) {
      this.selectedIndicators = indicators
    },
    setOutputFiles (outputFiles) {
      this.outputFiles = outputFiles
    },
    async validateGeojsonFile (file) {
      if (file) {
        this.runningGeojsonValidation = true
        this.geojsonValidationError = false
        await this.$graphqlQuery(
          getLineStringFromGeojson,
          { geojsonFile: file },
        ).then(response => {
          this.geojsonLines = response.getLineStringFromGeojson.geojson
          this.runningGeojsonValidation = false
        }).catch(() => {
          this.runningGeojsonValidation = false
          this.geojsonValidationError = true
        })
      }
    },
    async createTask () {
      const multiline = {
        lines: this.lines.map(line => line.geometry.coordinates.map(point => (
          { longitude: point[0], latitude: point[1] }),
        )),
      }
      await this.$graphqlMutate(
        createTaskMutation,
        {
          west: this.bbox[0],
          south: this.bbox[1],
          east: this.bbox[2],
          north: this.bbox[3],
          multiline,
          dataSource: this.selectedDataSource.code,
          indicators: this.selectedIndicators.map(i => i.code),
          geotiff: this.outputFiles.rawData,
          standaloneMaps: this.outputFiles.standaloneMaps,
        },
      ).then(response => {
        console.assert(response.createTask)
        this.$router.push({ name: resultsRoute.name })
      })
    },
    goToStep (step) {
      this.currentStep = step
    },
  },
}
</script>
<template>
  <section class="view create-analysis">
    <div class="analysis-stepper">
      <CreateAnalysisStepper
        :bbox="bbox"
        :current-step="currentStep"
        :geojson-validation-error="geojsonValidationError"
        :lines="lines"
        :matching-data-sources="matchingDataSources"
        :ready="sourcesListIsReady"
        :running-geojson-validation="runningGeojsonValidation"
        :selected-data-source="selectedDataSource"
        :possible-indicators="possibleIndicators"
        :selected-indicators="selectedIndicators"
        @selectedDataSourceUpdated="setSelectedDataSource"
        @selectedIndicatorsUpdated="setSelectedIndicators"
        @outputFilesUpdated="setOutputFiles"
        @goToStep="goToStep"
        @createTask="createTask"
        @validateGeojsonFile="validateGeojsonFile"
      />
    </div>
    <MapView
      :current-step="currentStep"
      :geojson-lines="geojsonLines"
      :loading="runningGeojsonValidation"
      :data-sources="dataSources"
      @bboxUpdated="setBBox"
      @linesUpdated="setLines"
    />
  </section>
</template>
<style lang="scss" scoped>
.create-analysis {
  flex-direction: row;
  gap: 20px;
  max-height: calc(100vh - 50px);
}
.analysis-stepper {
  flex-grow: 1;
  max-width: 45%;
}
</style>
