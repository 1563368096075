<script>
import { Mapbox, Popup } from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { MapboxMap, MapboxNavigationControl } from '@studiometa/vue-mapbox-gl'
import { mapboxPublicKey } from '@src/config'
import { COORDINATES_ORIGIN, CREATE_ANALYSIS_STEPS } from '@src/constants'
import { bbox, bboxPolygon } from '@turf/turf'

export default {
  name: 'MapView',
  components: {
    MapboxMap,
    MapboxNavigationControl,
  },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    geojsonLines: {
      type: Object,
      required: false,
      default: () => {},
    },
    dataSources: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'bboxUpdated',
    'linesUpdated',
  ],
  data () {
    return {
      bbox: null,
      polygons: null,
      lines: null,
      drawPolygons: null,
      drawLines: null,
      drawControl: null,
      map: null,
      updatedPopupMs: 0,
    }
  },
  computed: {
    mapbox () {
      return Mapbox // this.mapbox is used by MglMap internally
    },
    mapboxPublicKey () {
      return mapboxPublicKey
    },
    mapboxStyle () {
      return 'mapbox://styles/mapbox/light-v9'
    },
    startingCenter () {
      return COORDINATES_ORIGIN
    },
    startingZoomLevel () {
      return 9
    },
  },
  watch: {
    bbox () {
      this.$emit('bboxUpdated', this.bbox)
    },
    currentStep () {
      this.switchToStep(this.currentStep)
    },
    lines () {
      this.$emit('linesUpdated', this.lines)
    },
    geojsonLines () {
      this.displayGeojsonLines()
    },
    dataSources () {
      this.displayDataSourceZones()
    },
  },
  methods: {
    setControls (draw, updateFunction) {
      this.clearControls()
      this.drawControl = draw
      this.map.addControl(draw)
      this.map.on('draw.create', updateFunction)
      this.map.on('draw.delete', updateFunction)
      this.map.on('draw.update', updateFunction)
    },
    clearControls () {
      if (this.map.hasControl(this.drawPolygons)) {
        this.map.removeControl(this.drawPolygons)
      }
      if (this.map.hasControl(this.drawLines)) {
        this.map.removeControl(this.drawLines)
      }
      this.map.off('draw.create', this.updatePolygonsAndDisplayBBox)
      this.map.off('draw.delete', this.updatePolygonsAndDisplayBBox)
      this.map.off('draw.update', this.updatePolygonsAndDisplayBBox)
      this.map.off('draw.create', this.updateLines)
      this.map.off('draw.delete', this.updateLines)
      this.map.off('draw.update', this.updateLines)
      this.drawControl = null
    },
    switchToStep (step) {
      switch (step) {
        case CREATE_ANALYSIS_STEPS.ZONE:
          this.activateStepPolygons()
          break
        case CREATE_ANALYSIS_STEPS.SELECT_SOURCE:
          this.clearControls()
          break
        case CREATE_ANALYSIS_STEPS.LINE:
          this.activateStepLines()
          break
        case CREATE_ANALYSIS_STEPS.LAUNCH:
          this.activateStepCreateAnalysis()
          break
        default:
          break
      }
    },
    activateStepPolygons () {
      this.setControls(this.drawPolygons, this.updatePolygonsAndDisplayBBox)
      if (this.polygons) {
        this.drawPolygons.set(this.polygons)
        const features = this.drawPolygons.getAll().features
        this.drawPolygons.changeMode('simple_select', { featureIds: features.map(feature => feature.id) })
        this.updatePolygonsAndDisplayBBox()
      }
      if (this.lines) {
        this.displayLines()
      }
    },
    activateStepLines () {
      this.setControls(this.drawLines, this.updateLines)
      if (this.lines) {
        this.drawLines.set(this.lines)
        const features = this.drawLines.getAll().features
        this.drawLines.changeMode('simple_select', { featureIds: features.map(feature => feature.id) })
        this.clearLinesLayers()
      }
    },
    activateStepCreateAnalysis () {
      this.clearControls()
      this.displayLines()
    },
    onMapLoaded (e) {
      this.map = e
      this.map.on('load', this.initializeMap)
    },
    initializeMap () {
      this.drawPolygons = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      })
      this.drawLines = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          line_string: true,
          trash: true,
        },
      })
      this.switchToStep(this.currentStep)
    },
    updatePolygonsAndDisplayBBox () {
      this.polygons = this.drawPolygons.getAll()
      this.bbox = this.polygons.features.length ? bbox(this.polygons) : null
      if (this.bbox) {
        this.displaySelectedAreaBBox()
      } else {
        this.clearBBoxLayers()
      }
    },
    updateLines () {
      this.lines = this.drawLines.getAll()
    },
    setDataSourcePopup () {
      const popup = new Popup({
        closeButton: false,
        closeOnClick: false,
      })
      const layerName2dsName = new Map(this.dataSources.map(
        dataSource => [`${dataSource.name}_area`, dataSource.name],
      ))
      const updateDelayMs = 300
      const gettext = this.$gettext
      const showPopupEvent = e => {
        const currentMs = Date.now()
        if (
          (!this.drawControl || this.drawControl.getMode() == MapboxDraw.constants.modes.SIMPLE_SELECT)
          &&
          (this.updatedPopupMs == 0 || this.updatedPopupMs + updateDelayMs < currentMs)
        ) {
          const features = this.map.queryRenderedFeatures(e.point).filter(
            feature => layerName2dsName.has(feature.layer.id),
          )
          if (features.length) {
            this.map.getCanvas().style.cursor = 'pointer'
            const coordinates = e.lngLat
            const description = [...new Set(features.map(
              feature => gettext(layerName2dsName.get(feature.layer.id)),
            ))].sort().join('<br>')
            popup.setLngLat(coordinates).setHTML(description).addTo(this.map)
          } else {
            this.map.getCanvas().style.cursor = ''
            popup.remove()
          }
          this.updatedPopupMs = currentMs
        }
      }
      const hidePopupEvent = () => {
        this.map.getCanvas().style.cursor = ''
        popup.remove()
      }
      this.map.on('mouseenter', showPopupEvent)
      this.map.on('mousemove', showPopupEvent)
      this.map.on('mouseleave', hidePopupEvent)
      if (this.drawControl) {
        this.map.on('draw.modechange', e => {
          if (e.mode != MapboxDraw.constants.modes.SIMPLE_SELECT) {
            hidePopupEvent()
          }
        })
      }
    },
    displayDataSourceZone (dataSource) {
      const sourceName = dataSource.name
      const sourceColor = dataSource.color
      this.map.addSource(sourceName, {
        type: 'geojson',
        data: dataSource.boundingPolygon,
      })
      this.map.addLayer({
        id: `${sourceName}_area`,
        type: 'fill',
        source: sourceName,
        paint: {
          'fill-color': sourceColor,
          'fill-opacity': 0.1,
          'fill-antialias': false,
        },
      })
      this.map.addLayer({
        id: `${sourceName}_outline`,
        type: 'line',
        source: sourceName,
        paint: {
          'line-color': sourceColor,
          'line-width': 4,
          'line-opacity': 0.4,
        },
      })
    },
    displayDataSourceZones () {
      this.dataSources.forEach(dataSource  => this.displayDataSourceZone(dataSource))
      this.setDataSourcePopup()
    },
    displaySelectedAreaBBox () {
      const source = this.map.getSource('selected-area')
      const area = bboxPolygon(this.bbox)
      if (source) {
        source.setData(area)
      } else {
        this.map.addSource('selected-area', {
          type: 'geojson',
          data: area,
        })
      }
      if (!this.map.getLayer('analysis-area')) {
        this.map.addLayer({
          id: 'analysis-area',
          type: 'fill',
          source: 'selected-area',
          paint: {
            'fill-color': '#888888',
            'fill-opacity': 0.4,
            'fill-outline-color': 'black',
          },
          filter: ['==', '$type', 'Polygon'],
        })
      }
    },
    displayLines () {
      const source = this.map.getSource('drawn-lines')
      if (source) {
        source.setData(this.lines)
      } else {
        this.map.addSource('drawn-lines', {
          type: 'geojson',
          data: this.lines,
        })
      }
      if (!(this.map.getLayer('white-lines') && this.map.getLayer('red-lines'))) {
        this.map.addLayer({
          id: 'white-lines',
          type: 'line',
          source: 'drawn-lines',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': 'white',
            'line-width': 5,
          },
        })
        this.map.addLayer({
          id: 'red-lines',
          type: 'line',
          source: 'drawn-lines',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#d22328',
            'line-dasharray': [4, 3],
            'line-width': 5,
          },
        })
      }
    },
    clearBBoxLayers () {
      if (this.map.getLayer('analysis-area')) {
        this.map.removeLayer('analysis-area')
      }
      if (this.map.getSource('selected-area')) {
        this.map.removeSource('selected-area')
      }
    },
    clearLinesLayers () {
      if (this.map.getLayer('white-lines')) {
        this.map.removeLayer('white-lines')
      }
      if (this.map.getLayer('red-lines')) {
        this.map.removeLayer('red-lines')
      }
      if (this.map.getSource('drawn-lines')) {
        this.map.removeSource('drawn-lines')
      }
    },
    displayGeojsonLines () {
      this.clearLinesLayers()
      this.drawLines.add(this.geojsonLines)
      this.updateLines()
    },
  },
}
</script>
<template>
  <section class="map-view card">
    <div
      v-if="loading"
      class="overlay"
    />
    <MapboxMap
      :map-style="mapboxStyle"
      style="height: 100%;"
      :access-token="mapboxPublicKey"
      :attribution-control="false"
      :drag-rotate="false"
      :touch-zoom-rotate="false"
      :pitch-with-rotate="false"
      :center="startingCenter"
      :zoom="startingZoomLevel"
      @mb-created="onMapLoaded"
    >
      <MapboxNavigationControl
        :show-compass="false"
        position="bottom-right"
      />
    </MapboxMap>
  </section>
</template>
<style lang="scss" scoped>
.map-view {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
}
.overlay {
  position: absolute;
  background: rgb(255 255 255 / 0%);
  z-index: 10000;
  display: block;
  width: 100%;
  height: 100%;
}
</style>
