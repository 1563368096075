<script>
export default {
  name: 'Welcome',
}
</script>
<template>
  <section class="card welcome-panel">
    <span class="welcome-subtitle">
      {{ $gettext('Welcome to') }}
    </span>
    <img
      class="title"
      src="/static/climateplus-logo.png"
      alt="ClimatePlus"
    >
    <div class="welcome-text">
      <div class="first-section">
        <!-- eslint-disable-next-line max-len -->
        {{ $gettext('ClimatePlus maps future climate projections on a project area for planning and feasibility studies. It provides a visualization of the evolution of average temperature, precipitation and wind speed for the short (2050), medium (2070) and long term (2100), using 1976-2000 and 1980-1999 as reference periods.') }}
      </div>
      <div>
        <!-- eslint-disable-next-line max-len -->
        {{ $gettext('Future climate projections correspond to 2 selected Representative Concentration Pathways (RCP) for greenhouse gas (GHG) in the atmosphere by the end of the century (2100), as defined by the Intergovernmental Panel on Climate Change (IPCC). These are the RCP 4.5 (moderate GHG emissions) and the RCP 8.5 (high GHG emissions).') }}
      </div>
    </div>
    <img
      class="welcome-icon"
      src="/static/climateplus-icon.png"
    >
  </section>
</template>
<style lang="scss" scoped>
.welcome-panel {
  max-width: 40%;
  overflow: auto;
}
.title {
  margin-bottom: 1rem;
  width: 80%
}
.first-section {
  padding-bottom: 30px;
}
.welcome-text {
  font-size: 1.2rem;
  position: relative;
  z-index: 2;
}
.welcome-icon {
  position: absolute;
  width: 40%;
  z-index: 1;
  opacity: 0.6;
  right: 25px;
  bottom: 25px;
}

@media (width <= 1000px) {
  .welcome {
    width: max-content;
  }
}

@media (width <= 1400px), (height <= 850px) {
  .title {
    font-size: 3rem !important;
  }
  .subtitle {
    font-size: 1.6rem;
  }
}
</style>
