class Horizon {
  constructor (name, isRef, period) {
    this.name = name
    this.isRef = isRef
    this.period = period
  }
}

const defaultHorizons = [
  new Horizon('REF', true, '1980-1999'),
  new Horizon('H1', false, '2000-2099'),
  new Horizon('H2', false, '2100-2199'),
  new Horizon('H3', false, '2200-2299'),
]

class Indicator {
  constructor (code, names, definitions, group) {
    this.code = code
    // definitions depends on datasource, but used for translation, only the first one is used for sample
    this.name = names[0] // filled by the backend
    // definitions depends on datasource, but used for translation, only the first one is used for sample
    this.definition = definitions[0] // filled by the backend
    this.source = '' // filled by the backend
    this.horizons = defaultHorizons // filled by the backend
    this.group = group // used for grouping, could be 'temp' or 'wind'
  }
}

const $gettext = s => s // for easygettext to peek translations

/* eslint-disable max-len */
const indicators = [
  new Indicator(
    'hwdays',
    [
      $gettext('Number of days with temperature >40°C'),
      $gettext('Number of heat wave days'),
    ],
    [
      $gettext("Annual maximum of the monthly number of days with a maximum temperature above 40°C, median over 20 years from 21 CORDEX-AFR models for RCP 4.5 / 31 CORDEX-AFR models for RCP 8.5"),
      $gettext("Annual maximum of the monthly number of days with a maximum temperature above 40°C, median over 20 years from 13 CORDEX-AUS models for RCP 4.5 / 22 CORDEX-AUS models for RCP 8.5"),
      $gettext("Annual maximum of the monthly number of days with a maximum temperature above 40°C, median over 20 years from 20 CORDEX-EUR models for RCP 4.5 / 48 CORDEX-EUR models for RCP 8.5"),
      $gettext("Based on climatological EURO-CORDEX definition, averaged over 30 years and 8 EURO-CORDEX models (https://confluence.ecmwf.int/display/CKB/Health)"),
      $gettext("Number of heatwave days per year (DRIAS definition), median over 30 years from 10 models for RCP 4.5 / 12 models for RCP 8.5"),
      $gettext("Annual maximum of the monthly number of days with a maximum temperature above 40°C, median over 20 years from 5 CORDEX-SEA models for RCP 4.5 / 12 CORDEX-SEA models for RCP 8.5"),
      $gettext("Annual maximum of the monthly number of days with a maximum temperature above 40°C, median over 20 years from 19 CORDEX-WAS models for RCP 4.5 / 26 CORDEX-WAS models for RCP 8.5"),
    ],
    'temp',
  ),
  new Indicator(
    'tas',
    [$gettext('Mean temperatures')],
    [
      $gettext("Annual average of daily air temperature near the surface (2 meters), median over 20 years from 21 CORDEX-AFR models for RCP 4.5 / 31 CORDEX-AFR models for RCP 8.5"),
      $gettext("Annual average of daily air temperature near the surface (2 meters), median over 20 years from 13 CORDEX-AUS models for RCP 4.5 / 22 CORDEX-AUS models for RCP 8.5"),
      $gettext("Annual average of daily air temperature near the surface (2 meters), median over 20 years from 21 CORDEX-EUR models for RCP 4.5 / 50 CORDEX-EUR models for RCP 8.5"),
      $gettext("Yearly average of daily mean temperatures, averaged over 30 years and 8 EURO-CORDEX models (https://confluence.ecmwf.int/display/CKB/Health)"),
      $gettext("Annual average of daily temperatures, median over 30 years from 10 models for RCP 4.5 / 12 models for RCP 8.5"),
      $gettext("Annual average of daily air temperature near the surface (2 meters), median over 20 years from 5 CORDEX-SEA models for RCP 4.5 / 12 CORDEX-SEA models for RCP 8.5"),
      $gettext("Annual average of daily air temperature near the surface (2 meters), median over 20 years from 19 CORDEX-WAS models for RCP 4.5 / 26 CORDEX-WAS models for RCP 8.5"),
    ],
    'temp',
  ),
  new Indicator(
    'tasmin',
    [$gettext('Minimum temperatures')],
    [
      $gettext("Annual minimum of daily air temperature near the surface (2 meters), median over 20 years from 21 CORDEX-AFR models for RCP 4.5 / 31 CORDEX-AFR models for RCP 8.5"),
      $gettext("Annual minimum of daily air temperature near the surface (2 meters), median over 20 years from 13 CORDEX-AUS models for RCP 4.5 / 22 CORDEX-AUS models for RCP 8.5"),
      $gettext("Annual minimum of daily air temperature near the surface (2 meters), median over 20 years from 20 CORDEX-EUR models for RCP 4.5 / 48 CORDEX-EUR models for RCP 8.5"),
      $gettext("Yearly P1 percentile of daily minimal temperatures, averaged over 30 years and 8 EURO-CORDEX models (https://confluence.ecmwf.int/display/CKB/Health)"),
      $gettext("10th percentile of annual daily minimum temperatures, median over 30 years from 10 models for RCP 4.5 / 12 models for RCP 8.5"),
      $gettext("Annual minimum of daily air temperature near the surface (2 meters), median over 20 years from 5 CORDEX-SEA models for RCP 4.5 / 12 CORDEX-SEA models for RCP 8.5"),
      $gettext("Annual minimum of daily air temperature near the surface (2 meters), median over 20 years from 19 CORDEX-WAS models for RCP 4.5 / 26 CORDEX-WAS models for RCP 8.5"),
    ],
    'temp',
  ),
  new Indicator(
    'tasmax',
    [$gettext('Maximum temperatures')],
    [
      $gettext("Annual maximum of daily air temperature near the surface (2 meters), median over 20 years from 21 CORDEX-AFR models for RCP 4.5 / 31 CORDEX-AFR models for RCP 8.5"),
      $gettext("Annual maximum of daily air temperature near the surface (2 meters), median over 20 years from 13 CORDEX-AUS models for RCP 4.5 / 22 CORDEX-AUS models for RCP 8.5"),
      $gettext("Annual maximum of daily air temperature near the surface (2 meters), median over 20 years from 20 CORDEX-EUR models for RCP 4.5 / 48 CORDEX-EUR models for RCP 8.5"),
      $gettext("Yearly P99 percentile of daily maximum temperatures, averaged over 30 years and 8 EURO-CORDEX models (https://confluence.ecmwf.int/display/CKB/Health)"),
      $gettext("99th percentile of annual daily maximum temperatures, median over 30 years from 10 models for RCP 4.5 / 12 models for RCP 8.5"),
      $gettext("Annual maximum of daily air temperature near the surface (2 meters), median over 20 years from 5 CORDEX-SEA models for RCP 4.5 / 12 CORDEX-SEA models for RCP 8.5"),
      $gettext("Annual maximum of daily air temperature near the surface (2 meters), median over 20 years from 19 CORDEX-WAS models for RCP 4.5 / 26 CORDEX-WAS models for RCP 8.5"),
    ], 'temp',
  ),
  new Indicator(
    'pmean',
    [$gettext('Mean precipitation')],
    [
      $gettext("Annual average of daily cumulative precipitation, median over 20 years from 21 CORDEX-AFR models for RCP 4.5 / 31 CORDEX-AFR models for RCP 8.5"),
      $gettext("Annual average of daily cumulative precipitation, median over 20 years from 13 CORDEX-AUS models for RCP 4.5 / 22 CORDEX-AUS models for RCP 8.5"),
      $gettext("Annual average of daily cumulative precipitation, median over 20 years from 21 CORDEX-EUR models for RCP 4.5 / 50 CORDEX-EUR models for RCP 8.5"),
      $gettext("Annual mean of daily precipitations, averaged over 20 years and 10 EURO-CORDEX models"),
      $gettext("Annual average of daily cumulative precipitation, median over 30 years from 10 models for RCP 4.5 / 12 models for RCP 8.5"),
      $gettext("Annual average of daily cumulative precipitation, median over 20 years from 5 CORDEX-SEA models for RCP 4.5 / 12 CORDEX-SEA models for RCP 8.5"),
      $gettext("Annual average of daily cumulative precipitation, median over 20 years from 19 CORDEX-WAS models for RCP 4.5 / 26 CORDEX-WAS models for RCP 8.5"),
    ],
    'wind',
  ),
  new Indicator(
    'pmax',
    [$gettext('Maximum daily precipitation')],
    [
      $gettext("Annual maximum of daily cumulative precipitation, median over 20 years from 21 CORDEX-AFR models for RCP 4.5 / 31 CORDEX-AFR models for RCP 8.5"),
      $gettext("Annual maximum of daily cumulative precipitation, median over 20 years from 13 CORDEX-AUS models for RCP 4.5 / 22 CORDEX-AUS models for RCP 8.5"),
      $gettext("Annual maximum of daily cumulative precipitation, median over 20 years from 21 CORDEX-EUR models for RCP 4.5 / 48 CORDEX-EUR models for RCP 8.5"),
      $gettext("Annual maximum daily precipitations, averaged over 20 years and 10 EURO-CORDEX models"),
      $gettext("99th percentile of annual daily cumulative precipitation, median over 30 years from 10 models for RCP 4.5 / 12 models for RCP 8.5"),
      $gettext("Annual maximum of daily cumulative precipitation, median over 20 years from 5 CORDEX-SEA models for RCP 4.5 / 12 CORDEX-SEA models for RCP 8.5"),
      $gettext("Annual maximum of daily cumulative precipitation, median over 20 years from 19 CORDEX-WAS models for RCP 4.5 / 26 CORDEX-WAS models for RCP 8.5"),
    ],
    'wind',
  ),
  new Indicator(
    'wmax',
    [
      $gettext('Average wind speed'),
      $gettext('Wind gust speed'),
    ],
    [
      $gettext("Annual average of wind speed near the surface (10 meters), median over 20 years from 21 CORDEX-AFR models for RCP 4.5 / 31 CORDEX-AFR models for RCP 8.5"),
      $gettext("Annual average of wind speed near the surface (10 meters), median over 20 years from 13 CORDEX-AUS models for RCP 4.5 / 22 CORDEX-AUS models for RCP 8.5"),
      $gettext("Annual average of wind speed near the surface (10 meters), median over 20 years from 20 CORDEX-EUR models for RCP 4.5 / 48 CORDEX-EUR models for RCP 8.5"),
      $gettext("Annual maximum of mean monthly wind speed, averaged over 20 years and 7 EURO-CORDEX models"),
      $gettext("98th percentile of daily strong wind speeds, median over 30 years from 10 models for RCP 4.5 / 12 models for RCP 8.5"),
      $gettext("Annual average of wind speed near the surface (10 meters), median over 20 years from 4 CORDEX-SEA models for RCP 4.5 / 11 CORDEX-SEA models for RCP 8.5"),
      $gettext("Annual average of wind speed near the surface (10 meters), median over 20 years from 11 CORDEX-WAS models for RCP 4.5 / 20 CORDEX-WAS models for RCP 8.5"),
    ],
    'wind',
  ),
]
/* eslint-enable max-len */
const indicatorMap = new Map(indicators.map(i => [i.code, i]))
export default indicatorMap
