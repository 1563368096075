<script>
import Step from '@comp/stepper/Step.vue'

export default {
  name: 'Stepper',
  components: {
    Step,
  },
  model: {
    prop: 'currentStep',
    event: 'change',
  },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'change',
  ],
  methods: {
    goToStep (step) {
      this.$emit('change', step)
    },
    getStepHeaderName (step) {
      return `header-${step}`
    },
    getStepContentName (step) {
      return `content-${step}`
    },
    getStepFooterName (step) {
      return `footer-${step}`
    },
    getStepIndex (step) {
      return this.steps.findIndex(s => s === step )
    },
  },
}
</script>
<template>
  <section class="stepper">
    <Step
      v-for="step in steps"
      :key="step"
      :step-index="getStepIndex (step)"
      :current-step-index="getStepIndex (currentStep)"
      @go-to-step="goToStep"
    >
      <template #header>
        <slot :name="getStepHeaderName(step)" />
      </template>
      <template #content>
        <slot :name="getStepContentName(step)" />
      </template>
      <template #footer>
        <slot :name="getStepFooterName(step)" />
      </template>
    </Step>
  </section>
</template>
<style lang="scss" scoped>
.stepper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
</style>
