<script>
export default {
  name: 'SelectSourceStepContent',
  props: {
    dataSources: {
      type: Array,
      default: () => [],
    },
    selectedSource: {
      type: Object,
      default: () => null,
    },
  },
  emits: [
    'sourceSelected',
  ],
  methods: {
    onSelectSource (source) {
      this.$emit('sourceSelected', source)
    },
    isSelectedSource (source) {
      return source && this.selectedSource && source.code === this.selectedSource.code
    },
  },
}
</script>
<template>
  <div
    v-if="dataSources.length"
    class="indications"
  >
    {{ $gettext('Select the data source:') }}
    <div
      class="data-sources"
    >
      <v-card
        v-for="source in dataSources"
        :key="source.name"
        :variant="isSelectedSource(source) ? 'elevated' : 'outlined'"
        color="secondary"
        class="mx-auto"
        link
        @click="onSelectSource(source)"
      >
        <v-card-item
          prepend-icon="fas fa-upload"
        >
          <div class="text-h6 mb-1">
            {{ $gettext(source.name) }}
          </div>
          <div class="text-caption">
            {{ $gettext(source.description) }}
          </div>
        </v-card-item>
      </v-card>
    </div>
  </div>
  <div
    v-else
    class="indications"
  >
    <!-- eslint-disable-next-line max-len -->
    <i>{{ $gettext('We don\'t know how you ended up here, but there are no data sources matching the current bounding box.') }}</i>
  </div>
</template>
<style lang="scss" scoped>
.data-sources {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1ch;
}
.data-sources > .v-card {
  width: 100%;
}
</style>
