import gql from 'graphql-tag'

export const createTaskMutation = gql`
  mutation (
    $south: Latitude!
    $west: Longitude!
    $north: Latitude!
    $east: Longitude!
    $multiline: MultiLineInput!
    $dataSource: DataSourceEnum!
    $indicators: [IndicatorEnum!]!
    $geotiff: Boolean!
    $standaloneMaps: Boolean!
  ) {
    createTask(data: {
      box: {
        southWest: {
          longitude: $west
          latitude: $south
        },
        northEast: {
          longitude: $east
          latitude: $north
        }
      }
      multiline: $multiline
      dataSource: $dataSource
      indicators: $indicators
      withGeotiff: $geotiff
      withStandaloneMaps: $standaloneMaps
    }) {
      id
    }
  }
`
export const deleteTaskMutation = gql`
  mutation (
    $id: ID!
  ) {
    deleteTask (pk: $id) {
      id
    }
  }
`
