<script>
import { GEOJSON_SIZE_LIMIT } from '@src/constants'

export default {
  name: 'StepLineContent',
  props: {
    runningGeojsonValidation: {
      type: Boolean,
      default: false,
    },
    geojsonValidationError: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'validateGeojsonFile',
  ],
  data () {
    return {
      isHeavyFile: false,
    }
  },
  computed: {
    geojsonSizeLimit () {
      return GEOJSON_SIZE_LIMIT
    },
  },
  methods: {
    handleFileImport () {
      window.addEventListener('focus', () => {
      }, { once: true })
      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      this.isHeavyFile = false
      const file = e.target.files[0]
      if (file.size < GEOJSON_SIZE_LIMIT) {
        this.validateGeojsonFile(file)
      } else {
        this.isHeavyFile = true
      }
    },
    validateGeojsonFile (file) {
      this.$emit('validateGeojsonFile', file)
    },
  },
}
</script>
<template>
  <div class="indications">
    <div>
      <h3>{{ $gettext('Draw on the map:') }}</h3>
    </div>
    <ol>
      <li>
        {{ $gettext('Click on the button') }}
        <v-icon>
          <img
            src="/static/line_icon.svg"
            alt="line_icon"
            class="line-icon"
          >
        </v-icon>
        {{ $gettext('on the map to activate the drawing tool') }}
      </li>
      <li>{{ $gettext('Click on the map to draw lines') }}</li>
      <li>{{ $gettext('Double-click or press "Enter" to end your line') }}</li>
    </ol>
  </div>
  <h4 class="my-4">
    {{ $gettext('And / Or') }}
  </h4>
  <div class="indications">
    <div>
      <h3>{{ $gettext('Import from file:') }}</h3>
    </div>
    <p>
      {{
        $gettext('To import lines from a file, click on the "Upload lines" button below ' +
          'and select a .geojson file containing the desired lines.')
      }}
    </p>
    <v-btn
      color="secondary"
      variant="outlined"
      :disabled="runningGeojsonValidation"
      @click="handleFileImport"
    >
      <v-progress-circular
        v-if="runningGeojsonValidation"
        color="gray"
        indeterminate
        width="2"
        size="18"
        class="mr-1 px-4"
      />
      <v-icon
        v-else
        size="small"
        start
      >
        fas fa-upload
      </v-icon>
      {{ $gettext('Upload Lines') }}
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="application/geo+json"
      @change="onFileChanged"
    >
    <v-alert
      v-if="isHeavyFile"
      type="error"
      icon="fas fa-exclamation-triangle"
      rounded="lg"
    >
      {{ $gettext('The uploaded file exceeds the %sizeLimit%MB file size limit. ' +
        'Please choose a smaller file to upload.').replace('%sizeLimit%', Math.round(geojsonSizeLimit/1000000)) }}
    </v-alert>
    <v-alert
      v-if="geojsonValidationError"
      type="error"
      icon="fas fa-exclamation-triangle"
      rounded="lg"
    >
      {{ $gettext('An error occurred while validating the uploaded file. ' +
        'Please ensure the file meets the specified format, size, and type restrictions and try again.') }}
    </v-alert>
    <p>
      <i>{{
        $gettext('*If you have already performed an analysis and generated ' +
          'a line drawing on the map, you can locate the corresponding file ' +
          'named "analysis.geojson" within the result zip file.\nThe file must ' +
          'contain maximum 100 lines.')
      }}</i>
    </p>
  </div>
  <div class="result-indication mt-4">
    <p>{{ $gettext('The lines you draw will be stylized to make it visible on the maps.') }}</p>
  </div>
</template>
<style lang="scss" scoped>
.line-icon {
  height: 1.2rem;
}
</style>
